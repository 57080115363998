/*
You can add global styles to this file, and also import other style files

Most common desktop resolutions:
1366x768
1920x1080
1536x864
1440x900
1280x720
1600x900
1280x800
1280x1024
1024x768

*/

@import "~@ng-select/ng-select/themes/material.theme.css";

body,
html {
  display: grid;
  height: 100%;
}

.ball-loader {
  width: 100px;
  height: 23.3333333333px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.ball-loader-ball {
  will-change: transform;
  height: 23.3333333333px;
  width: 23.3333333333px;
  border-radius: 50%;
  background-color: lightBlue;
  position: absolute;
  animation: grow 1s ease-in-out infinite alternate;
}

.ball-loader-ball.ball1 {
  left: 0;
  transform-origin: 100% 50%;
}

.ball-loader-ball.ball2 {
  left: 50%;
  transform: translateX(-50%) scale(1);
  animation-delay: 0.33s;
}

.ball-loader-ball.ball3 {
  right: 0;
  animation-delay: 0.66s;
}

@keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}

button {
  -webkit-box-sizing: border-box;
  background-color: #f5f5f5;
  border-radius: 2px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: #555;
  cursor: pointer;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  line-height: 21px !important;
  margin: 2px;
  min-width: 15px;
  padding: 0 4px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
}

button:active {
  background-color: #d7d7d7;
  border-color: #aaa;
  color: #303030;
}

button:focus, button:hover {
  background-color: #e7e7e7;
  border-color: #9b9b9b;
  color: #555;
}

.page-container {
  display: grid;
  height: 100%;
}

.hidden {
  display: none;
}


/* --------------------------------------- Vault UI style ------------------------ */
.endpoint-container {
  margin-top: 50px;
  margin-left: 20px;
}

.buttons {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  width: auto;
  box-shadow: none;
  padding: 3px 10px 3px 10px;
  min-width: 70px;
  border-radius: 2px;
  outline: none;
  border: none;
}

.buttons :active {
  border: none;
  outline: none;
}

.buttons :focus {
  border: none;
  outline: none;
}

.gray-thin-line {
  height: 1px;
  background-color: #e1e6e8;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;
}

/* end Vault UI style */

